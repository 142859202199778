import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class MerchandiserProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getAll () {
    this.setHeader(getAuthToken())
    return this.get('/merchandisers?page=1&limit=9999999&sort_by=name&sort_order=asc')
  }

  getOne (id) {
    this.setHeader(getAuthToken())
    return this.get(`/merchandisers/${id}`)
  }

  createOne (form) {
    this.setHeader(getAuthToken())
    return this.post('/merchandisers', form)
  }

  updateOne (id, form) {
    this.setHeader(getAuthToken())
    return this.post(`/merchandisers/${id}`, form)
  }

  deleteOne (id) {
    this.setHeader(getAuthToken())
    return this.get(`/merchandisers/${id}`)
  }
}

export default MerchandiserProvider
