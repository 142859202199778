<template>
  <section id="detail-box">
    <v-expansion-panels
      v-model="activeIndex"
      accordion
      @input="test()">
      <v-expansion-panel>
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <span class="detail-box--panel-header">
            Sketch
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <sketch-detail-box
            :sketch="sketch"
            :state="prototypeState"
            @create-order-click="createOrderClick()"
            @img-click="bindImg($event)" />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="prototypeState !== 'sketch'">
        <v-expansion-panel-header expand-icon="mdi-menu-down">
          <span class="detail-box--panel-header">
            Order
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <order-detail-box
            :order="order"
            :show-remake-btn="showRemakeBtn"
            @img-click="bindImg($event)"
            @remake-sketch-click="remakeClicked()" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </section>
</template>

<script>
import { prototypeData } from '@/assets/js/DefaultPrototypeData'
import SketchDetailBox from './SketchDetailBox.vue'
import OrderDetailBox from './OrderDetailBox.vue'

export default {
  components: {
    SketchDetailBox,
    OrderDetailBox
  },
  props: {
    prototypeSelected: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      activeIndex: 0
    }
  },
  computed: {
    prototype () {
      const result = {
        ...prototypeData,
        ...this.prototypeSelected
      }
      this.setActiveIndex(result.state)
      return result
    },
    sketch () {
      return this.prototype.sketch
    },
    order () {
      return this.prototype.order
    },
    prototypeState () {
      return this.prototype.state || ''
    },
    showRemakeBtn () {
      return this.prototypeState === 'manufacturing'
    }
  },
  methods: {
    setActiveIndex (state) {
      if (state === 'sketch') {
        this.activeIndex = 0
      } else {
        this.activeIndex = 1
      }
    },
    createOrderClick () {
      this.$emit('create-order-click', this.prototypeSelected.id)
    },
    bindImg (src) {
      this.$emit('img-click', src)
    },
    remakeClicked () {
      this.$emit('remake-sketch-click', this.prototypeSelected.id)
    }
  }
}
</script>

<style scoped>
p.field-label {
  font-weight: bold;
  margin-bottom: 0;
}
.material-row {
  position: relative;
}
.material-row::before {
  content: attr(data-count)'.';
  position: absolute;
  top: 20px;
  left: 0;
}
img.detail-box-image {
  cursor: pointer;
  transition: transform 0.1s;
}
img.detail-box-image:active {
  transform: scale(0.95);
}
.field-label span {
  font-weight: normal;
}
.detail-box-note {
  white-space: pre-line;
}
</style>
