export const prototypeData = {
  status: null,
  sketch: {
    images: [],
    materials: [],
    sketch_id: '',
    designer: {
      id: null,
      name: null
    },
    factory: {
      id: null,
      name: null
    },
    category: {
      id: null,
      name: null
    },
    merchandiser: {
      id: null,
      name: null
    },
    collection_name: null,
    note: null,
    size_detail: [],
    model: 'string'
  },
  state: null,
  createdAt: null,
  updatedAt: null,
  order: {
    orderId: null,
    fitting: [
      {
        images: [],
        note: ''
      }
    ],
    orderStatus: 'draft',
    orderDate: null,
    factory: {
      name: null,
      id: null
    },
    productId: null,
    productFactoryId: null,
    sellPrice: null,
    targetDate: null,
    clothesCostPerMetr: null,
    clothesLength: null,
    manufacCost: null,
    manufacCountry: null,
    materials: [],
    sizeDetail: [],
    productSkus: [],
    updatedAt: null,
    createdAt: null
  },
  id: null
}

export default {
  prototypeData
}
