<template>
  <v-row
    v-if="order"
    id="order-detail-box"
    class="px-4 py-4">
    <v-col
      v-for="(fitting, fittingIndex) in fittings"
      :key="`fitting-${fittingIndex}`"
      cols="12">
      <p class="field-label">
        Fitting Image:
      </p>
      <v-row>
        <template v-if="fitting.images && fitting.images.length">
          <v-col
            v-for="(image, index) in fitting.images"
            :key="`fitting-images-${index}`"
            :cols="6"
            class="detail-box-image-box">
            <img
              :src="image"
              class="detail-box-image"
              @click="bindImg(image)" />
          </v-col>
        </template>
        <v-col
          v-else
          :cols="12">
          <div class="d-flex align-center justify-center pa-2 ma-2">
            No image !
          </div>
        </v-col>
        <v-col cols="12">
          <p class="field-label">
            Fitting Note:
          </p>
          <p class="detail-box-note mb-0">
            {{ fitting.note }}
          </p>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      v-if="isNewSchema"
      cols="12">
      <p class="field-label">
        ผ้า:
      </p>
      <v-row
        v-for="(c, cIndex) in colors"
        :key="`color-${cIndex}`">
        <v-col cols="12">
          <div class="capitalize bold">
            {{ c.color || '-' }}
          </div>
        </v-col>
        <v-col
          v-if="c.fabrics && c.fabrics.length"
          cols="12">
          <v-row
            v-for="(fabric, index) in c.fabrics"
            :key="'fabric' + index"
            :data-count="index+1"
            class="mx-0 px-5 material-row">
            <v-col cols="12">
              <v-col
                v-for="image in fabric.imageUrls"
                :key="`materialImg-${image}`"
                :cols="6"
                class="detail-box-image-box">
                <img
                  :src="image"
                  class="detail-box-image"
                  @click="bindImg(image)" />
              </v-col>
            </v-col>
            <v-col
              v-if="fabric.patterns"
              cols="12">
              <p class="field-label">
                ลายผ้า:
              </p>
              <v-row
                v-for="(pattern, indexPattern) in fabric.patterns"
                :key="`pattern-${index}-${indexPattern}`">
                <v-col
                  v-for="(img, imgIndex) in pattern.imageUrls"
                  :key="`pattern-${index}-${indexPattern}-${imgIndex}`"
                  cols="6">
                  <img
                    :src="img"
                    class="detail-box-image"
                    @click="bindImg(img)" />
                </v-col>
                <v-col cols="12">
                  <p class="field-label">
                    หมายเหตุ:
                    <span>
                      {{ pattern.note || '-' }}
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                รหัส Sketch:
                <span>
                  {{ fabric.sketchId || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                รหัสผ้า:
                <span>
                  {{ fabric.fabricNo || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                รหัสแฮงค์:
                <span>
                  {{ fabric.hangId || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                โรงงาน:
                <span>
                  {{ fabric.factory || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                ขนาด:
                <span>
                  {{ fabric.size || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                สี:
                <span>
                  {{ fabric.color || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                คอลเลคชั่น:
                <span>
                  {{ fabric.collectionName || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                จำนวน:
                <span>
                  {{ fabric.amount || 0 }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                ราคาต่อหน่วย:
                <span class="text-uppercase">
                  {{ `${fabric.unitPrice || 0} ${fabric.currency || 'thb'}` }}
                </span>
              </p>
            </v-col>
            <v-col cols="12">
              <p class="field-label">
                หมายเหตุ:
                <span>
                  {{ fabric.note || '-' }}
                </span>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-else
          cols="12">
          <v-row class="mx-0 px-5">
            <v-col :cols="12">
              <div class="d-flex align-center justify-center pa-2 ma-2">
                No data !
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="isNewSchema"
      cols="12">
      <v-divider />
    </v-col>
    <v-col
      v-if="isNewSchema"
      cols="12">
      <p class="field-label">
        อะไหล่:
      </p>
      <v-row
        v-for="(c, cIndex) in colors"
        :key="`color-${cIndex}`">
        <v-col cols="12">
          <div class="capitalize bold">
            {{ c.color || '-' }}
          </div>
        </v-col>
        <v-col
          v-if="c.materials && c.materials.length"
          cols="12">
          <v-row
            v-for="(material, index) in c.materials"
            :key="'material' + index"
            :data-count="index+1"
            class="mx-0 px-5 material-row">
            <v-col cols="12">
              <v-col
                v-for="image in material.imageUrls || material.images"
                :key="`materialImg-${image}`"
                :cols="6"
                class="detail-box-image-box">
                <img
                  :src="image"
                  class="detail-box-image"
                  @click="bindImg(image)" />
              </v-col>
            </v-col>
            <v-col
              v-if="material.patterns"
              cols="12">
              <p class="field-label">
                ลายอะไหล่:
              </p>
              <v-row
                v-for="(pattern, indexPattern) in material.patterns"
                :key="`pattern-${index}-${indexPattern}`">
                <v-col
                  v-for="(img, imgIndex) in pattern.imageUrls"
                  :key="`pattern-${index}-${indexPattern}-${imgIndex}`"
                  cols="6">
                  <img
                    :src="img"
                    class="detail-box-image"
                    @click="bindImg(img)" />
                </v-col>
                <v-col cols="12">
                  <p class="field-label">
                    หมายเหตุ:
                    <span>
                      {{ pattern.note || '-' }}
                    </span>
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                รหัส Sketch:
                <span>
                  {{ material.sketchId || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                ชื่อ:
                <span>
                  {{ material.name || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                โรงงาน:
                <span>
                  {{ material.factory || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                วัสดุ:
                <span>
                  {{ material.material || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                ขนาด:
                <span>
                  {{ material.size || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                สี:
                <span>
                  {{ material.color || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                คอลเลคชั่น:
                <span>
                  {{ material.collectionName || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                จำนวน:
                <span>
                  {{ material.amount || 0 }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                ราคาต่อหน่วย:
                <span class="text-uppercase">
                  {{ `${material.unitPrice || 0} ${material.currency || 'thb'}` }}
                </span>
              </p>
            </v-col>
            <v-col cols="12">
              <p class="field-label">
                หมายเหตุ:
                <span>
                  {{ material.note || '-' }}
                </span>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-else
          cols="12">
          <v-row class="mx-0 px-5">
            <v-col :cols="12">
              <div class="d-flex align-center justify-center pa-2 ma-2">
                No data !
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-else
      cols="12">
      <p class="field-label">
        อะไหล่:
      </p>
      <v-row>
        <v-col
          v-if="materials.length"
          cols="12">
          <v-row
            v-for="(material, index) in materials"
            :key="'material' + index"
            :data-count="index+1"
            class="mx-0 px-5 material-row">
            <v-col cols="12">
              <v-col
                v-for="image in material.images"
                :key="`materialImg-${image}`"
                :cols="6"
                class="detail-box-image-box">
                <img
                  :src="image"
                  class="detail-box-image"
                  @click="bindImg(image)" />
              </v-col>
            </v-col>
            <v-col cols="12">
              <p class="field-label">
                หมายเหตุ:
                <span>
                  {{ material.note || '-' }}
                </span>
              </p>
            </v-col>
            <v-col cols="6">
              <p class="field-label">
                จำนวน:
                <span>
                  {{ material.quantity || 0 }}
                </span>
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-else
          cols="12">
          <v-row class="mx-0 px-5">
            <v-col :cols="12">
              <div class="d-flex align-center justify-center pa-2 ma-2">
                No data !
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12">
      <table>
        <tr>
          <td>
            <p class="field-label">
              Product ID
            </p>
          </td>
          <td>
            <p class="ml-3">
              {{ orderProductId }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="field-label">
              Product Factory ID
            </p>
          </td>
          <td>
            <p class="ml-3">
              {{ orderProductFactoryId }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="field-label">
              Factory
            </p>
          </td>
          <td>
            <p class="ml-3">
              {{ orderfactoryName }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="field-label">
              Order Date
            </p>
          </td>
          <td>
            <p class="ml-3">
              {{ orderOrderDate }}
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p class="field-label">
              Target Date
            </p>
          </td>
          <td>
            <p class="ml-3">
              {{ orderTargetDate }}
            </p>
          </td>
        </tr>
      </table>
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      v-if="isNewSchema"
      cols="12">
      <p class="field-label">
        Amount & Cost
      </p>
      <div
        v-for="(c, i) in mappedSkus"
        :key="i"
        class="mt-5">
        <p class="field-label text-capitalize">
          {{ c.color }}
        </p>
        <v-simple-table v-if="c.skus.length">
          <thead>
            <tr>
              <th class="field-label text-center">
                Size
              </th>
              <th class="field-label text-center">
                Color
              </th>
              <th class="field-label text-center">
                Percent
              </th>
              <th class="field-label text-center">
                Quantity
              </th>
              <th class="field-label text-center">
                Ratio
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(sku, index) in c.skus"
              :key="`product-sku-${index}`">
              <td class="text-center text-uppercase">
                {{ sku.size }}
              </td>
              <td class="text-center text-uppercase">
                {{ sku.color }}
              </td>
              <td class="text-center">
                {{ sku.percent | showNumberFormat() }}%
              </td>
              <td class="text-center">
                {{ sku.quantity | showNumberFormat() }}
              </td>
              <td class="text-center">
                {{ sku.ratio | showFullPriceFormat() }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td class="text-center bold">
                Total
              </td>
              <td class="text-center bold">
                {{ c.totalQty | showNumberFormat() }}
              </td>
              <td></td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-row
          v-else
          class="mx-0 px-5">
          <v-col :cols="12">
            <div class="d-flex align-center justify-center pa-2 ma-2">
              No data !
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col
      v-else
      cols="12">
      <p class="field-label">
        Amount & Cost
      </p>
      <v-simple-table v-if="productSkus.length > 0">
        <thead>
          <tr>
            <th class="field-label text-center">
              Size
            </th>
            <th class="field-label text-center">
              Color
            </th>
            <th class="field-label text-center">
              Quantity
            </th>
            <th class="field-label text-center">
              Ratio
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(sku, index) in productSkus"
            :key="`product-sku-${index}`">
            <td class="text-center text-uppercase">
              {{ sku.size }}
            </td>
            <td class="text-center text-uppercase">
              {{ sku.color }}
            </td>
            <td class="text-center">
              {{ sku.quantity }}
            </td>
            <td class="text-center">
              {{ sku.ratio | showFullPriceFormat() }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-row
        v-else
        class="mx-0 px-5">
        <v-col :cols="12">
          <div class="d-flex align-center justify-center pa-2 ma-2">
            No data !
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12">
      <p class="field-label">
        Sizing Detail
      </p>
      <v-simple-table v-if="sizeDetails.length > 0">
        <thead>
          <tr>
            <th>
              <p class="label mb-0 text-center">
                Detail / Size
              </p>
            </th>
            <th
              v-for="(size) in sizeDetails"
              :key="`head-sizeDetails-${size.size}`">
              <p class="label mb-0 text-uppercase text-center">
                {{ size.size }}
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(key, keyIndex) in keys"
            :key="`mainkey-${keyIndex}`">
            <td>
              <p class="label mb-0 text-center">
                {{ key }}
              </p>
            </td>
            <td
              v-for="(size, sizeIndex) in sizeDetails"
              :key="`sizeDetails-${sizeIndex}-${keyIndex}`">
              <p class="label mb-0 text-center">
                {{ size.options[keyIndex].value }}
              </p>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-row
        v-else
        class="mx-0 px-5">
        <v-col :cols="12">
          <div class="d-flex align-center justify-center pa-2 ma-2">
            No data !
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="showRemakeBtn"
      cols="12"
      class="d-flex justify-center">
      <v-btn
        color="secondary"
        @click="remakeClicked()">
        REMAKE
      </v-btn>
    </v-col>
  </v-row>
  <v-row
    v-else
    class="mx-0 px-5">
    <v-col cols="12">
      <div class="d-flex align-center justify-center pa-2 ma-2">
        No data !
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    showRemakeBtn: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isNewSchema () {
      return 'colors' in this.order
    },
    sizeDetails () {
      return this.order?.sizeDetail ? this.order.sizeDetail : []
    },
    keys () {
      const { sizeDetails } = this
      if (!Array.isArray(sizeDetails) || sizeDetails.length === 0 || !Array.isArray(sizeDetails[0].options) || sizeDetails[0].options.length === 0) {
        return []
      }
      return sizeDetails[0].options.map((option) => option.key)
    },
    skuSizesHeaders () {
      if (this.order?.sizeDetail) {
        return this.order?.sizeDetail[0]?.options.map((option) => option.key) || ['size']
      }
      return []
    },
    fittings () {
      const fitting = this.order?.fitting
      let tmpFitting = null

      if (fitting && !Array.isArray(fitting)) {
        tmpFitting = [fitting]
      } else {
        tmpFitting = [...fitting]
      }

      return tmpFitting || []
    },
    orderProductId () {
      return this.order?.productId || '-'
    },
    orderProductFactoryId () {
      return this.order?.productFactoryId || '-'
    },
    orderfactoryName () {
      return this.order?.factory?.name || '-'
    },
    orderOrderDate () {
      return this.order?.orderDate ? this.$dayjs(this.order?.orderDate).format('DD/MM/YYYY') : '-'
    },
    orderTargetDate () {
      return this.order?.targetDate ? this.$dayjs(this.order?.targetDate).format('DD/MM/YYYY') : '-'
    },
    productSkus () {
      return this.order?.productSkus || []
    },
    colors () {
      return this.order?.colors || []
    },
    mappedSkus () {
      const indexedSkus = this.productSkus.map((sku, index) => ({ ...sku, index }))
      const colors = this.colors.map((c) => {
        const skus = indexedSkus.filter((sku) => sku.color === c.color)

        return {
          color: c.color,
          totalQty: c?.totalQty || skus.reduce((sum, sku) => sum + Number(sku.quantity || 0), 0),
          skus
        }
      })

      return colors
    },
    materials () {
      return this.isNewSchema ? [] : (this.order?.materials || [])
    }
  },
  methods: {
    isArrayHaveItem (item, key) {
      if (key && item && item[key]) {
        return item[key].length
      }

      return null
    },
    bindImg (src) {
      this.$emit('img-click', src)
    },
    getDetailValue (options, size) {
      const { value } = options.find((option) => option.key === size)
      return value || '-'
    },
    remakeClicked () {
      this.$emit('remake-sketch-click')
    }
  }
}
</script>

<style scoped>
#order-detail-box p {
  margin-bottom: 0;
}
p.field-label {
  font-weight: bold;
  margin-bottom: 0;
}
.material-row {
  position: relative;
}
.material-row::before {
  content: attr(data-count)'.';
  position: absolute;
  top: 20px;
  left: 0;
}
img.detail-box-image {
  cursor: pointer;
  transition: transform 0.1s;
}
img.detail-box-image:active {
  transform: scale(0.95);
}
.field-label span {
  font-weight: normal;
}
.detail-box-note {
  white-space: pre-line;
}
.bold {
  font-weight: bold;
}
.capitalize {
  text-transform: capitalize;
}
</style>
