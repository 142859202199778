import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class FactoryProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getAll () {
    this.setHeader(getAuthToken())
    return this.get('/factories?page=1&limit=9999999&sort_by=name&sort_order=asc')
  }

  getOne (id) {
    this.setHeader(getAuthToken())
    return this.get(`/factories/${id}`)
  }

  createOne (form) {
    this.setHeader(getAuthToken())
    return this.post('/factories', form)
  }

  updateOne (id, form) {
    this.setHeader(getAuthToken())
    return this.put(`/factories/${id}`, form)
  }

  deleteOne (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/factories/${id}`)
  }
}

export default FactoryProvider
