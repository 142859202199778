<template>
  <v-row class="px-4 py-4">
    <v-col cols="6">
      <p class="field-label">
        ID:
        <span> {{ sketchId }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Type:
        <span> {{ categoryName }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Date:
        <span> {{ createdAtDate }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Time:
        <span> {{ createdAtTime }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Days:
        <span> {{ createdAtDiffNow }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Factory:
        <span> {{ factoryName }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Designer:
        <span> {{ designerName }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Merchandiser:
        <span> {{ merchandiserName }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Graphic:
        <span> {{ graphicName }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Creative:
        <span> {{ stylistName }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Collection:
        <span> {{ collectionName }} </span>
      </p>
    </v-col>
    <v-col cols="6">
      <p class="field-label">
        Classification:
        <span> {{ classification }} </span>
      </p>
    </v-col>
    <v-col cols="12">
      <p class="field-label">
        Note:
      </p>
      <p class="detail-box-note">
        {{ sketchNote }}
      </p>
    </v-col>
    <v-col cols="12">
      <p class="field-label">
        Sketch Image:
      </p>
      <v-row>
        <template v-if="hasSketchImages">
          <v-col
            v-for="(image, index) in sketch.images"
            :key="`sketchImg-${index}`"
            :cols="6"
            class="detail-box-image-box">
            <img
              :src="image"
              class="detail-box-image"
              @click="bindImg(image)" />
          </v-col>
        </template>
        <v-col
          v-else
          :cols="12">
          <div class="d-flex align-center justify-center pa-2 ma-2">
            No image !
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="isNewSchema"
      cols="12">
      <p class="field-label">
        ผ้า:
      </p>
      <template v-if="fabrics.length">
        <v-row
          v-for="(fabric, index) in sketch.fabrics"
          :key="'fabric' + index"
          :data-count="index+1"
          class="mx-0 px-5 material-row">
          <v-col cols="12">
            <v-col
              v-for="image in fabric.imageUrls"
              :key="`materialImg-${image}`"
              :cols="6"
              class="detail-box-image-box">
              <img
                :src="image"
                class="detail-box-image"
                @click="bindImg(image)" />
            </v-col>
          </v-col>
          <v-col
            v-if="fabric.patterns"
            cols="12">
            <p class="field-label">
              ลายผ้า:
            </p>
            <v-row
              v-for="(pattern, indexPattern) in fabric.patterns"
              :key="`pattern-${index}-${indexPattern}`">
              <v-col
                v-for="(img, imgIndex) in pattern.imageUrls"
                :key="`pattern-${index}-${indexPattern}-${imgIndex}`"
                cols="6">
                <img
                  :src="img"
                  class="detail-box-image"
                  @click="bindImg(img)" />
              </v-col>
              <v-col cols="12">
                <p class="field-label">
                  หมายเหตุ:
                  <span>
                    {{ pattern.note || '-' }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              รหัส Sketch:
              <span>
                {{ fabric.sketchId || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              รหัสผ้า:
              <span>
                {{ fabric.fabricNo || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              รหัสแฮงค์:
              <span>
                {{ fabric.hangId || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              โรงงาน:
              <span>
                {{ fabric.factory || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              ขนาด:
              <span>
                {{ fabric.size || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              สี:
              <span>
                {{ fabric.color || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              คอลเลคชั่น:
              <span>
                {{ fabric.collectionName || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              ราคาต่อหน่วย:
              <span class="text-uppercase">
                {{ `${fabric.unitPrice || 0} ${fabric.currency || 'thb'}` }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              จำนวน:
              <span>
                {{ fabric.amount || 0 }}
              </span>
            </p>
          </v-col>
          <v-col cols="12">
            <p class="field-label">
              หมายเหตุ:
              <span>
                {{ fabric.note || '-' }}
              </span>
            </p>
          </v-col>
        </v-row>
      </template>
      <v-row
        v-else
        class="mx-0 px-5">
        <v-col :cols="12">
          <div class="d-flex align-center justify-center pa-2 ma-2">
            No data !
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-if="isNewSchema"
      cols="12">
      <p class="field-label">
        อะไหล่:
      </p>
      <template v-if="materials.length">
        <v-row
          v-for="(material, index) in sketch.materials"
          :key="'material' + index"
          :data-count="index+1"
          class="mx-0 px-5 material-row">
          <v-col cols="12">
            <v-col
              v-for="image in material.imageUrls || material.images"
              :key="`materialImg-${image}`"
              :cols="6"
              class="detail-box-image-box">
              <img
                :src="image"
                class="detail-box-image"
                @click="bindImg(image)" />
            </v-col>
          </v-col>
          <v-col
            v-if="material.patterns"
            cols="12">
            <p class="field-label">
              ลายอะไหล่:
            </p>
            <v-row
              v-for="(pattern, indexPattern) in material.patterns"
              :key="`pattern-${index}-${indexPattern}`">
              <v-col
                v-for="(img, imgIndex) in pattern.imageUrls"
                :key="`pattern-${index}-${indexPattern}-${imgIndex}`"
                cols="6">
                <img
                  :src="img"
                  class="detail-box-image"
                  @click="bindImg(img)" />
              </v-col>
              <v-col cols="12">
                <p class="field-label">
                  หมายเหตุ:
                  <span>
                    {{ pattern.note || '-' }}
                  </span>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              รหัส Sketch:
              <span>
                {{ material.sketchId || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              ชื่อ:
              <span>
                {{ material.name || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              โรงงาน:
              <span>
                {{ material.factory || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              วัสดุ:
              <span>
                {{ material.material || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              ขนาด:
              <span>
                {{ material.size || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              สี:
              <span>
                {{ material.color || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              คอลเลคชั่น:
              <span>
                {{ material.collectionName || '-' }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              ราคาต่อหน่วย:
              <span class="text-uppercase">
                {{ `${material.unitPrice || 0} ${material.currency || 'thb'}` }}
              </span>
            </p>
          </v-col>
          <v-col cols="6">
            <p class="field-label">
              จำนวน:
              <span>
                {{ material.amount || 0 }}
              </span>
            </p>
          </v-col>
          <v-col cols="12">
            <p class="field-label">
              หมายเหตุ:
              <span>
                {{ material.note || '-' }}
              </span>
            </p>
          </v-col>
        </v-row>
      </template>
      <v-row
        v-else
        class="mx-0 px-5">
        <v-col :cols="12">
          <div class="d-flex align-center justify-center pa-2 ma-2">
            No data !
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      v-else
      cols="12">
      <p class="field-label">
        อะไหล่:
      </p>
      <template v-if="materials.length">
        <v-row
          v-for="(material, index) in sketch.materials"
          :key="'material' + index"
          :data-count="index+1"
          class="mx-0 px-5 material-row">
          <v-col cols="12">
            <v-col
              v-for="image in material.images"
              :key="`materialImg-${image}`"
              :cols="6"
              class="detail-box-image-box">
              <img
                :src="image"
                class="detail-box-image"
                @click="bindImg(image)" />
            </v-col>
          </v-col>
          <v-col cols="12">
            <p class="field-label">
              หมายเหตุ:
              <span>
                {{ material.note || '-' }}
              </span>
            </p>
          </v-col>
        </v-row>
      </template>
      <v-row
        v-else
        class="mx-0 px-5">
        <v-col :cols="12">
          <div class="d-flex align-center justify-center pa-2 ma-2">
            No data !
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <p class="field-label">
        Sizing Detail
      </p>
      <v-simple-table v-if="sizeDetails.length > 0">
        <thead>
          <tr>
            <th>
              <p class="label mb-0 text-center">
                Detail / Size
              </p>
            </th>
            <th
              v-for="(size) in sizeDetails"
              :key="`head-sizeDetails-${size.size}`">
              <p class="label mb-0 text-uppercase text-center">
                {{ size.size }}
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(key, keyIndex) in keys"
            :key="`mainkey-${keyIndex}`">
            <td>
              <p class="label mb-0 text-center">
                {{ key }}
              </p>
            </td>
            <td
              v-for="(size, sizeIndex) in sizeDetails"
              :key="`sizeDetails-${sizeIndex}-${keyIndex}`">
              <p class="label mb-0 text-center">
                {{ size.options[keyIndex].value }}
              </p>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-row
        v-else
        class="mx-0 px-5">
        <v-col :cols="12">
          <div class="d-flex align-center justify-center pa-2 ma-2">
            No data !
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      class="d-flex justify-center">
      <v-btn
        color="secondary"
        @click="createOrderClick()">
        ส่งใบงาน
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    sketch: {
      type: Object,
      required: true
    }
  },
  computed: {
    isNewSchema () {
      return 'fabrics' in this.sketch
    },
    sizeDetails () {
      return this.sketch?.sizeDetail ? this.sketch.sizeDetail : []
    },
    keys () {
      const { sizeDetails } = this
      if (!Array.isArray(sizeDetails) || sizeDetails.length === 0 || !Array.isArray(sizeDetails[0].options) || sizeDetails[0].options.length === 0) {
        return []
      }
      return sizeDetails[0].options.map((option) => option.key)
    },
    sketchId () {
      return this.sketch?.sketchId || '-'
    },
    categoryName () {
      return this.sketch?.category?.name || '-'
    },
    designerName () {
      return this.sketch?.designer?.name || '-'
    },
    merchandiserName () {
      return this.sketch?.merchandiser?.name || '-'
    },
    graphicName () {
      return this.sketch?.graphic?.name || '-'
    },
    stylistName () {
      return this.sketch?.stylist?.name || '-'
    },
    factoryName () {
      return this.sketch?.factory?.name || '-'
    },
    collectionName  () {
      return this.sketch?.collectionName || '-'
    },
    classification  () {
      return this.sketch?.classification || '-'
    },
    sketchNote () {
      return this.sketch?.note || '-'
    },
    createdAtDate () {
      return this.sketch?.createdAt ? this.$dayjs(this.sketch.createdAt).format('DD/MM/YYYY') : '-'
    },
    createdAtTime () {
      return this.sketch?.createdAt ? this.$dayjs(this.sketch.createdAt).format('HH:mm') : '-'
    },
    createdAtDiffNow () {
      return this.sketch?.createdAt ? this.$dayjs().diff(this.$dayjs(this.sketch.createdAt), 'day') + 1 : '-'
    },
    materials () {
      return this.sketch?.materials || []
    },
    fabrics () {
      return this.sketch?.fabrics || []
    },
    hasSketchImages () {
      return this.sketch?.images?.length > 0
    }
  },
  methods: {
    createOrderClick () {
      this.$emit('create-order-click')
    },
    bindImg (src) {
      this.$emit('img-click', src)
    },
    getDetailValue (options, size) {
      const { value } = options.find((option) => option.key === size)
      return value || '-'
    }
  }
}
</script>

<style scoped>
p.field-label {
  font-weight: bold;
  margin-bottom: 0;
}
.material-row {
  position: relative;
}
.material-row::before {
  content: attr(data-count)'.';
  position: absolute;
  top: 20px;
  left: 0;
}
img.detail-box-image {
  cursor: pointer;
  transition: transform 0.1s;
}
img.detail-box-image:active {
  transform: scale(0.95);
}
.field-label span {
  font-weight: normal;
}
.detail-box-note {
  white-space: pre-line;
}
</style>
