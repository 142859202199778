<template>
  <tbody>
    <tr
      v-for="(item, index) in innerItems"
      :key="`prototype-list-row-${item.id}`"
      :class="{ 'prototype-selected': prototypeSelectedId === item.id }"
      @click="clickRow(item, index)">
      <td
        v-for="header in headers"
        :key="`prototype-list-col-${header.value}`"
        :class="getTdClass(header.align)">
        <slot
          :name="`item.${header.value}`"
          :item="item"
          :value="item.prototypeTableValue[header.value]">
          <template v-if="header.value === 'data-table-select'">
            <v-checkbox
              v-model="item.isSelected"
              class="ma-0"
              :ripple="false"
              dense
              hide-details
              @change="select(item, $event)" />
          </template>
          <template v-else>
            {{ item.prototypeTableValue[header.value] }}
          </template>
        </slot>
      </td>
    </tr>
    <tr v-if="isNoData">
      <td :colspan="columnAmount">
        {{ noDataText }}
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    isSelected: {
      type: Function,
      default: () => null
    },
    select: {
      type: Function,
      default: () => null
    },
    blankItem: {
      type: String,
      default: ''
    },
    prototypeSelectedId: {
      type: String,
      default: ''
    },
    searchText: {
      type: String,
      default: ''
    }
  },
  computed: {
    innerItems () {
      return this.items.map((item) => {
        const prototypeTableValue = {}
        this.headers.forEach((header) => {
          prototypeTableValue[header.value] = this.checkBlankValue(item, header.value)
        })
        return {
          ...item,
          prototypeTableValue,
          isSelected: this.isSelected(item)
        }
      })
    },
    isNoData () {
      return this.innerItems.length === 0
    },
    noDataText () {
      return this.searchText !== '' ? `No matches "${this.searchText}" found` : 'No matches data found'
    },
    columnAmount () {
      return this.headers.length
    }
  },
  watch: {
    prototypeSelectedId (newVal) {
      const index = this.innerItems.findIndex((item) => item.id === newVal)
      if (index > -1) {
        this.innerItems.splice(index, 1, this.innerItems[index])
      }
    }
  },
  methods: {
    clickRow (item, index) {
      this.$emit('click:row', { item, index })
    },
    getTdClass (align) {
      return align ? `text-${align}` : 'text-start'
    },
    checkBlankValue (item, key) {
      if (typeof item === 'undefined') {
        return false
      }
      const index = key.indexOf('.')
      if (index > -1 && item[key.substring(0, index)]) {
        return this.checkBlankValue(item[key.substring(0, index)], key.substr(index + 1))
      }
      return item[key] || this.blankItem
    }
  }
}
</script>

<style lang="scss" scoped>
.prototype-selected {
  background-color: #eeeeee !important;
}
</style>
